import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import Cta from '../components/LandingPage/Cta/Cta';
import BlockContentProjectsCarousel from 'src/components/BlockContentProjectsCarousel/BlockContentProjectsCarousel';
import { CtaWithAnimation, Faq, FullWidthVideoHero, OurProcess, Partners, Testimonial } from 'src/components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';

function IndexPage({ data, location }) {
  const {
    sanityBasicPage: {
      home: { faq, ourProcess, partners, fullWidthVideoHero, secondaryCta, testimonial },
      title,
      pageSeo,
    },
    allSanityCtaDocument: { edges },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout layoutClassName="index-page">
      <Container fluid className="bg-shapes-banner px-0">
        <PageSEO {...seo} />
        <FullWidthVideoHero fullWidthVideoHero={fullWidthVideoHero} />
        <BlockContentProjectsCarousel />
        {/* eslint-disable react/jsx-boolean-value */}
        <Partners partners={partners} isIndex={true} />
        <Container fluid className="bg-shapes-clouds-dark-v1 overflow-hidden px-0">
          <Testimonial testimonial={testimonial} />
          {edges.map((e) => (
            <Cta
              cta={{
                title: e.node?.title,
                tagline: e.node?.tagline,
                link: e.node?.link,
                isTwoColLayout: false,
                image: '',
                isDark: true,
                skipImage: true,
              }}
            />
          ))}

          <OurProcess ourProcess={ourProcess} />
        </Container>
        <CtaWithAnimation ctaWithAnimation={secondaryCta} />
        <Container fluid className="px-0">
          <Faq faq={faq} />
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      home {
        partners {
          ...getPartners
        }
        testimonial {
          ...getTestimonial
        }
        ourProcess {
          ...getOurProcess
        }
        fullWidthVideoHero {
          ...getFullWidthVideoHero
        }
        secondaryCta {
          ...getCtaWithAnimation
        }
        faq {
          ...getFaq
        }
      }
    }
    allSanityCtaDocument(filter: { _id: { eq: "caed0c8b-bdb7-4769-809e-167fe17c8958" } }) {
      edges {
        node {
          title
          tagline
          link {
            _key
            _type
            title
            url
          }
          _id
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
export default IndexPage;
